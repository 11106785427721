<template>
    <div class="main-view" v-show="initFinished">

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                实名认证
            </div>
        </div>

        <van-form @submit="onSubmit" ref="submitForm" style="margin-top: 110px;">
            <div style="margin: 30px 0 20px;">
                <div v-if="!isRealNameCheck" style="color: #888888;font-size: 14px;text-align: left;margin: 0 25px;">
                    根据国家相关法律法规要求，进入游戏需要实名认证
                </div>
                <div v-else style="color: #888888;font-size: 14px;text-align: left;margin: 0 25px;">
                    该账号已实名认证
                </div>
            </div>


            <van-field label="真实姓名" label-width="80" label-class="inline-label" :readonly="isRealNameCheck" placeholder="请输入真实姓名"
                        style="margin-top: 2px;color: #ffffff;padding: 16px 30px;font-size: 16px;" v-model="realName" clearable/>

            <van-field label="身份证号" label-width="80" label-class="inline-label" :readonly="isRealNameCheck" placeholder="请输入身份证号"
                        style="margin-top: 10px;color: #ffffff;padding: 16px 30px;font-size: 16px;" v-model="carId" clearable/>

            <div v-show="!isRealNameCheck" class="box-button">
                <van-button type="info" block native-type="submit" :loading="loading" size="normal" round style="width: 200px;font-size: 18px;" loading-text="正在认证" color="#e73d50">
                    立即认证
                </van-button>
            </div>

        </van-form>
    </div>
</template>
<script>

    import {Toast} from "vant";
    import {webTokenRequest} from "@/api/request";

    export default {
        name: 'app-realName',
        components: {
        },
        data() {
            return {
                realName: '',
                carId: '',
                isRealNameCheck: false,
                loading: false,
                realNamePattern: /^[\u4E00-\u9FA5]{1,5}$/,
                carIdPattern: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][012])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
                initFinished: false,
            }
        },
        mounted() {
            this.refreshLoginInfo();
        },
        methods: {
            back() {
                this.$router.back();
            },
            refreshLoginInfo() {
                let options = {
                    url:"/web/getLoginInfo",
                    method: 'get'
                };
                webTokenRequest(options).then((res) => {
                    if (res.data.success === true) {
                        this.baseInfo = res.data.data;
                        localStorage.setItem('web_baseInfo', JSON.stringify(this.baseInfo));
                        this.initPage();
                        this.initFinished = true;
                    } else {
                        Toast.fail('登录已失效，请重新登录');
                        localStorage.removeItem('web_baseInfo');
                        localStorage.removeItem('web_token');
                        this.back();
                    }
                });
            },
            initPage() {
                let baseInfo = JSON.parse(localStorage.getItem('web_baseInfo'));
                if (baseInfo.isUserCheck === '1') {
                    this.isRealNameCheck = true;
                    this.carId = baseInfo.cardNo;
                    this.realName = baseInfo.realName;
                }
                else {
                    this.isRealNameCheck = false;
                    this.carId = '';
                    this.realName = '';
                }
            },
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let options = {
                    url:"/web/personCheck",
                    data: {
                        cardNo: this.carId,
                        realName: this.realName,
                    }
                };
                webTokenRequest(options).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast.success('实名认证成功');
                        this.refreshLoginInfo();

                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },

            checkForm() {
                if (!this.realName) {
                    return "请输入真实姓名";
                }
                /*if (!this.realNamePattern.test(this.realName)) {
                    return "真实姓名格式错误";
                }*/
                if (!this.carId) {
                    return "请输入身份证号码";
                }
                if (!this.carIdPattern.test(this.carId)) {
                    return "身份证号码格式错误";
                }
                return "ok";
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f8f7f5;
    }
    .m-title {
        display: flex;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }

    /deep/.inline-label {
        color: #333333 !important;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
</style>
