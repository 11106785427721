<template>
    <div class="main-view">

        <div class="m-title">
            <div style="width: 100%; display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                <div @click="" class="search-input-button p-hidden-view">
                    <img src="../../assets/search.png" style="width: 18px;height: 18px;" >
                    <input class="search-input" v-model="gameName" type="text" :placeholder="webConfig.defaultSearchName">
                </div>
                <div @click="searchGame" style="width: 70px;text-align: center;cursor: pointer">
                    <span style="font-size: 18px;color: #333333;">搜索</span>
                </div>
            </div>
        </div>

        <div v-if="showSearchInfo" class="search-info-view">
            <div class="search-hot-view">
                <div style="text-align: left;font-size: 18px;margin: 0 20px;">
                    热搜词汇
                </div>
                <div class="name-view">
                    <span @click="clickSearch(item)" class="name-item-view" v-for="item in webConfig.hotSearchName">
                        {{item}}
                    </span>
                </div>
            </div>

            <div class="search-history-view" v-if="historySearchName.length > 0">
                <div style="display: flex;justify-content: space-between;align-items: center; font-size: 18px;margin: 0 20px;">
                    <span>搜索历史</span>
                    <van-icon @click="deleteHistory" name="delete-o" color="#888888" size="22"/>
                </div>
                <div class="name-view">
                    <span @click="clickSearch(item)" class="name-item-view" v-for="item in historySearchName">
                        {{item}}
                    </span>
                </div>
            </div>
        </div>

        <div v-else class="game-list-view">
            <div v-if="gameList.length > 0"  class="game-center" style="width: 100%;margin-top: 20px;display: flex;flex-wrap: wrap;justify-content: space-between;">
                <div class="game-item-view" v-for="item in gameList" @click="open(item.game_id)">
                    <img :src="item.image_url" class="game-img">
                    <div class="game-text-view">
                        <span class="game-name-one-line">{{item.game_name}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                        <div class="game-topic">
                            {{handlerTopic(item.game_topic)}}
                        </div>
                    </div>
                </div>
            </div>

            <div v-else style="font-size: 20px;width: 100%;text-align: center;height: 300px;color: #888888;display: flex;align-items: center;justify-content: center">
                暂无内容~
            </div>
        </div>
    </div>
</template>
<script>
    import {Dialog, Toast} from "vant";
    import {request} from "@/api/request";
    import config from "@/api/config";

    export default {
        name: 'app-search',
        components: {
        },
        data() {
            return {
                loading: false,
                webConfig: {},
                gameName: '',
                gameList: [],
                historySearchName: [],
                showSearchInfo: true,
            }
        },
        watch: {
            // 监控路由对象
            '$route'(to, from) {
                console.log(from.path)
                console.log(from.path === '/index')
                if (from.path === '/index') {
                    this.initPage();
                }
            }
        },
        mounted() {
            console.log('游戏搜索');
            this.initPage();
        },
        methods: {
            initPage() {
                this.showSearchInfo = true;
                this.webConfig = config;
                this.gameName = '';
                this.gameList = [];
                let SearchNameStr = localStorage.getItem('web_search_name');
                if (SearchNameStr) {
                    this.historySearchName = SearchNameStr.split(',');
                }
            },
            deleteHistory() {
                Dialog.confirm({
                    title: '提示',
                    message: '确认要删除搜索历史记录吗',
                }).then(() => {
                    localStorage.removeItem('web_search_name');
                    this.historySearchName = [];
                }).catch(() => {

                });
            },
            saveHistory(name) {
                let SearchNameStr = localStorage.getItem('web_search_name');
                if (SearchNameStr) {
                    let newArray = [];
                    newArray.push(name);

                    let saveArray = SearchNameStr.split(',');
                    for (let i = 0; i < saveArray.length; i++) {
                        if (newArray.length < 10 && saveArray[i] !== name) {
                            newArray.push(saveArray[i]);
                        }
                    }
                    localStorage.setItem('web_search_name', newArray.join(','));
                }
                else {
                    localStorage.setItem('web_search_name', name);
                }
            },
            back() {
                this.$router.back();
            },
            clickSearch(item) {
                this.gameName = item;
                this.searchGame();
            },
            searchGame() {
                let name = this.gameName || this.webConfig.defaultSearchName;
                this.saveHistory(name);
                this.showLoading = true;
                let options = {
                    url:"/web/queryGameList",
                    data: {
                        pageNo: 1,
                        pageSize: 999,
                        params: {
                            game_name : name
                        }
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.gameList = data.list;

                        //this.addData();
                        this.showSearchInfo = false;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            addData() {
                for (let i = 0; i < 20; i++) {
                    this.gameList.push(this.gameList[0])
                }
            },
            handlerTopic(t) {
                switch (t) {
                    case '1':
                        return '卡牌';
                    case '2':
                        return '传奇';
                    case '3':
                        return '武侠';
                    case '4':
                        return '角色';
                    case '5':
                        return '养成';
                    case '6':
                        return '三国';
                    case '7':
                        return '模拟';
                    case '8':
                        return '策略';
                    case '9':
                        return '休闲';
                }
            },
            open(gameId) {
                this.$router.push('/app/1/' + gameId);
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f8f7f5;
    }
    .search-input {
        height: 28px;
        border: none;
        font-size: 16px;
        color: #333333;
        font-weight: normal;
        text-align: left;
        width: 100%;
        padding: 0 8px;
    }
    .search-input::placeholder {
        color: #aaaaaa; /* 灰色的占位符文本 */
    }
    .search-info-view {
        margin-top: 110px;
        padding: 15px 0;
        color: #333333;
    }
    .name-view {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0 15px;
    }
    .name-item-view {
        background-color: #ffffff;
        border-radius: 35px;
        padding: 8px 15px;
        font-size: 13px;
        color: #666666;
        margin-right: 15px;
        cursor: pointer;
        margin-top: 15px;
    }
    .m-title {
        display: flex;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }
    .search-history-view {
        margin-top: 30px;
    }
    .van-cell{
        padding: 8px 14px;
    }

    .search-input-button {
        flex: 1;
        height: 45px;
        background-color: #ffffff;
        border-radius: 45px;
        margin: 0;
        display: flex;
        align-items: center;
        padding: 0 10px;
        color: #888888;
        border: 1px solid #eeeeee;
        box-shadow: 1px 1px 20px 3px rgba(0,0,0,.05);
    }
    .game-list-view {
        margin-top: 110px;
    }
    .game-img {
        width: 70px;
        height: 70px;
        border-radius: 10px;
    }
    .game-item-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        cursor: pointer;
        box-sizing: border-box;
        overflow: hidden;
        width: calc(100% - 40px) ;
        padding: 15px 15px;
        margin: 0 auto 10px;
    }
    .game-name-one-line {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        color: #333333
    }
    .game-tag-view {
        display: flex;
        color: #f15858;
        font-size: 12px;
        white-space: nowrap;
    }
    .game-tag-view span {
        margin-right: 10px;
        background-color: rgba(241, 88, 88, 0.1);
        padding: 2px 4px;
        border-radius: 2px;
    }
    .game-text-view {
        display: flex;
        height: 70px;
        margin-left: 15px;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden
    }
    .game-topic {
        font-size: 13px;
        color: #FFB300;
        text-align: left
    }
</style>
