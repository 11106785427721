<template>
    <div class="main-container">
        <div class="dialog-title">
            <img @click="back"  src="../assets/back.png" style="width: 25px;height: 25px;">
            <div style="margin-left: 5px;">礼包领取</div>
        </div>
        
        <div class="main-view">
            <!--    游戏信息    -->
            <div class="game-info">
                <img :src="imgUrl" style="width: 47px;height: 47px">
                <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-around;margin-left: 10px;overflow: hidden">
                    <span style="font-size: 12px;font-weight: 700">{{ gameName }}</span>
                    <div style="font-size: 14px;display: flex;flex-wrap: nowrap">
                        <span class="game-tag" v-for="tag in tagList" :key="tag.index">{{tag}}</span>
                    </div>
                </div>
            </div>

            <!--   充值记录     -->
            <div class="game-menu">
                <span style="font-size: 14px;font-weight: 700;margin: 8px;">我的充值记录</span>

                <div class="no-item-view" v-if="rechargeInfoList.length === 0">
                    <span class="none-text" >暂无充值记录</span>
                </div>

                <div v-for="(item,index) in rechargeInfoList" :key="item.index" style="width: 100%">
                    <div class="role-view" @click="openRecharge(index)">
                        <div style="display: flex;align-items: center">
                            <span style="font-size: 12px;" :style="item.selected ? 'font-weight: 700;color: #dc9e2f;' : ''">{{item.serverName}} / {{item.roleName}}</span>
                        </div>
                        <div style="display: flex;align-items: center">
                            <span  @click.stop="setRole(index)" v-show="!item.selected" style="font-size: 12px;padding: 2px 5px; color: #ED9015;border: 1px solid #ED9015;border-radius: 3px;">选择</span>
                            <span  v-show="item.selected" style="font-size: 12px;color: #dc9e2f;font-weight: bold">已选择</span>
                            <van-icon v-show="!item.open" name="arrow-down" color="#888888" style="margin-left: 15px;"/>
                            <van-icon v-show="item.open" name="arrow-up" color="#888888" style="margin-left: 15px;"/>
                        </div>
                    </div>
                    <div v-show="item.open">
                        <div  class="recharge-info">
                            <span>累计充值</span>
                            <span style="font-weight: bold">￥{{item.allAmount}}</span>
                        </div>
                        <div v-for="day in item.roleRechargeListList" :key="day.index" class="recharge-info">
                            <span>{{day.rechargeDate}}</span>
                            <span style="font-weight: bold">￥{{day.rechargeAmount}}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <!--     提示       -->
            <div style="font-size: 12px;color: #666666;text-align: left;margin-top: 8px;padding: 5px 3px;">
                <van-icon name="info-o" color="#aaaaaa"/>
                <span style="margin-left: 3px;color:#aaaaaa;">提示：领取礼包之前请先选择角色</span>
            </div>
            
            <div class="game-menu" style="margin-top: 8px;">
                <div @click="showRecordPage" style="display: flex;align-items: center;justify-content: space-between;padding: 8px 0;width: 90%;margin: 0 auto;font-size: 12px;">
                    <span>礼包领取记录</span>
                    <van-icon name="arrow" color="#888888" />
                </div>
            </div>
            
            <div class="game-menu" style="margin-top: 5px;">
                <div @click="openDateSelector" style="display: flex;align-items: center;justify-content: space-between;padding: 8px 0;width: 90%;margin: 0 auto;font-size: 12px;">
                    <span>选择充值日期</span>
                    <div style="display: flex;align-items: center;">
                        <span style="margin-right: 15px;">{{selectedDate}}</span>
                        <van-icon  name="arrow" color="#888888" />
                    </div>
                </div>
            </div>

            <van-popup v-model="showDateSelector" position="bottom" >
                <van-picker
                    title="请选择充值日期"
                    show-toolbar
                    :columns="selectDateList"
                    @confirm="onConfirmDate"
                    @cancel="showDateSelector = false"
                />
            </van-popup>

            <div style="margin-top: 8px;">
                <div class="game-menu"  v-for="(item,index) in list" :key="item.index" style="margin-top: 2px;">
                    <div @click="open(index)" style="display: flex;align-items: center;justify-content: space-between;padding: 6px 0;width: 90%;margin: 0 auto;font-size: 12px;">
                        <span style="font-size: 13px;font-weight: bold;color: #ef4d4d;">{{item.giftName}}</span>
                        <div style="display: flex;align-items: center">
                            <div v-if="item.status===0" @click.stop="openRoleIdPopup(item.giftId,item.status)" class="get-button">领取</div>
                            <div v-if="item.status===3" class="get-button">驳回</div>
                            <div v-if="item.status===4" class="get-button">同组已领</div>
                            <div v-if="item.status===-1" class="get-button">查看说明</div>
                            <div v-if="item.status===2" class="get-button">待审核</div>
<!--                            <div v-if="item.status===1" size="mini" round color="#DDDDDD" class="get-button">已发放</div>-->
                            <div v-if="item.status===1" @click.stop="copyGiftCode(item.giftCode)" class="get-button">复制</div>
                            <van-icon v-show="!item.open" name="arrow-down" color="#888888" />
                            <van-icon v-show="item.open" name="arrow-up" color="#888888" />
                        </div>
                    </div>
                  <div v-if="item.giftPercentage" style="color: #fcb684;text-align: left;padding: 0 0 6px;width: 90%;margin: 0 auto;font-size: 12px;">
                    <div>
                      <span>剩余：{{ item.giftPercentage }}%</span>
                    </div>
                  </div>
                    <div v-show="item.open" style="width: 85%;margin: 10px auto;text-align: left;font-size: 12px;color: #cccccc">
                        <div class="game-detail-info">
                            <span style="font-size: 13px;">领取条件</span>
                        </div>
                        <span style="margin: 10px 10px 0;display: block">
                            {{item.giftCondition || '无'}}
                        </span>
                        <div class="game-detail-info" style="margin-top: 15px;">
                            <span style="font-size: 13px;">礼包内容</span>
                        </div>
                        <span style="margin: 10px 10px 0;display: block">
                            {{item.giftContent || '无'}}
                        </span>
                        <div class="game-detail-info" style="margin-top: 15px;">
                            <span style="font-size: 13px;">礼包说明</span>
                        </div>
                        <span style="margin: 10px 10px 0;display: block;">
                            {{item.giftExplain || '无'}}
                        </span>
                    </div>
                </div>
            </div>

            <van-dialog v-model="showRoleInfoDialog" show-cancel-button title="请确认您的礼包领取信息" @confirm="doGetGift">
                <div style="font-size: 14px;padding: 15px 25px;">
                    <van-field class="common-input" type="text" label="QQ号码" label-width="58px" v-model="roleInfoQQ" clearable placeholder="请输入您的QQ号码"/>
                    <van-field class="common-input" type="text" label="微信" label-width="58px" v-model="roleInfoWx" clearable placeholder="请输入您的微信号码"/>
                    <van-field class="common-input" type="text" label="角色ID" label-width="58px" v-model="roleInfoId" clearable placeholder="请输入您的角色ID"/>
                </div>
            </van-dialog>
        </div>
        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
import {Dialog, Toast} from "vant";
import {request} from "@/api/request";
import Myloading from "@/components/myloading.vue";

export default {
    name: "Gift",
    components: {Myloading},
    data(){
        return {
            showLoading: false,
            gameId: '',
            imgUrl: '',
            gameName: '',
            list: [],
            tagList: [],
            rechargeInfoList: [],
            selectRole: {},
            selectedGiftId: '',
            showRoleInfoDialog: false,
            roleInfoQQ: '',
            roleInfoWx: '',
            roleInfoId: '',
            showDateSelector: false,
            selectDateList: [],
            selectedDate: '未选择',
            selectedIndex: -1,
            showClaimRecord: false,
        }
    },
    mounted() {
        this.gameId = localStorage.getItem("gameId");
        // this.gameId = '1';
        this.getData();
    },
    methods: {
        getParent() {
            let from = localStorage.getItem("from");
            if (from === 'android_sdk') {
                return  this.$parent;
            }
            else {
                return this.$parent.$parent;
            }
        },
        back() {
            this.getParent().closeGiftPage();
        },
        openRecharge(index){
            this.rechargeInfoList[index].open = !this.rechargeInfoList[index].open;
        },
        getData() {
            this.showLoading = true;
            let options = {
                url:"/app/getGameGiftList",
                data: {
                    "gameId": this.gameId
                }
            };
            request(options).then(res => {
                this.showLoading = false;
                if (res.data.success) {
                    let d = res.data.data;
                    this.imgUrl = d.gameImgUrl;
                    this.gameName = d.gameName;
                    this.tagList = d.tagList;
                    if (d.giftList) {
                        d.giftList.forEach(function(e,v) {
                            e.open = false;
                        });
                    }
                    this.list = d.giftList;
                    if (d.rechargeInfoList) {
                        d.rechargeInfoList.forEach(function(e,v) {
                            e.open = false;
                            e.selected = false;
                        });
                        this.rechargeInfoList = d.rechargeInfoList;
                    }

                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
        setRole(index) {
            this.rechargeInfoList.forEach(function(e,v) {
                e.selected = false;
            });
            this.selectRole = this.rechargeInfoList[index];
            this.rechargeInfoList[index].selected = !this.rechargeInfoList[index].selected;

            this.selectDateList = [];
            let me = this;
            this.rechargeInfoList[index].roleRechargeListList.forEach(function(e,v) {
                me.selectDateList.push(e.rechargeDate);
            });
            this.selectedIndex = -1;
            this.selectedDate = '未选择';

            this.refreshGiftList('角色选择成功');
        },
        showRecordPage(){
            this.getParent().showGiftRecordPage();
        },
        open(index) {
            this.list[index].open = !this.list[index].open;
        },
        openRoleIdPopup(giftId,status){
            this.selectedGiftId = giftId;
            this.showLoading = true;
            let options = {
                url:"/app/getUserReportRoleId",
                data: {
                    roleId: this.selectRole.roleId
                }
            };
            request(options).then(res => {
                this.showLoading = false;
                if (res.data.success) {
                    let d = res.data.data;
                    this.roleInfoQQ = d.qq;
                    this.roleInfoWx = d.wx;
                    this.roleInfoId = d.userReportRole;
                    if(status === 0 || status === 5){
                      this.doGetGift();
                      return;
                    }
                    this.showRoleInfoDialog = true;
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
        onConfirmDate(value, index) {
            if (this.selectDateList.length === 0) {
                Toast('当前没有账号可以选择');
                return ;
            }
            this.selectedDate = value;
            this.showDateSelector = false;
            this.refreshGiftList('日期选择成功');
        },
        refreshGiftList(title) {
            this.showLoading = true;
            let date_ = this.selectedDate === '未选择' ? '' : this.selectedDate;
            let options = {
                url:"/app/getGameGiftStatus",
                data: {
                    gameId: this.gameId,
                    roleCode: this.selectRole.orderRoleCode,
                    roleId: this.selectRole.roleId,
                    giftDate: date_,
                }
            };
            request(options).then(res => {
                this.showLoading = false;
                if (res.data.success) {
                    let d = res.data.data;
                    this.list.forEach(function(e,v) {
                        for(let i = 0; i < d.length ; i++) {
                            if (e.giftId === d[i].giftId) {
                                e.status = d[i].status
                                break;
                            }
                        }
                    });
                    if (title) {
                        //Toast(title);
                    }
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
        openDateSelector() {
            this.showDateSelector = true;
        },
        doGetGift() {
            this.showLoading = true;
            let date_ = this.selectedDate === '未选择' ? '' : this.selectedDate;
            let options = {
                url:"/app/getGift",
                data: {
                    gameId: this.gameId,
                    roleCode: this.selectRole.orderRoleCode,
                    roleId: this.selectRole.roleId,
                    giftId: this.selectedGiftId,
                    giftDate: date_,
                    qq: this.roleInfoQQ,
                    wx: this.roleInfoWx,
                    userReportRole: this.roleInfoId
                }
            };
            request(options).then(res => {
                this.showLoading = false;
                if (res.data.success) {
                    let d = res.data.data;
                    let me = this;
                    if (d.code === 1) {
                        this.refreshGiftList();
                        this.copyCode(d.msg);
                    }
                    else {
                        Toast('礼包申请成功，请等待客服审核');
                        this.refreshGiftList();
                    }
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
        copyCode(v) {
            Dialog.alert({
                title: '礼包码',
                message: '你的礼包码为' + v,
                showCancelButton: false,
                confirmButtonText: '复制'
            }).then(() => {
                const input = document.createElement('input')
                document.body.appendChild(input)
                input.setAttribute('value', v)
                input.select()
                if (document.execCommand('copy')) {
                    document.execCommand('copy')
                }
                document.body.removeChild(input)
            })
        },
        copyGiftCode(v) {
          const input = document.createElement('input')
          document.body.appendChild(input)
          input.setAttribute('value', v)
          input.select()
          if (document.execCommand('copy')) {
            document.execCommand('copy')
          }
          document.body.removeChild(input)
          this.$toast("复制成功");
        },
    }
}
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }
    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }

    .main-view{
        width: 95%;
        height: 255px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 5px 15px;
        margin: 0 auto;
    }
    .game-info {
        display: flex;
        background:rgba(255,255,255,.1);
        padding: 10px;
        margin-top: 5px;
        color: #ffffff;
        /*box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);*/
    }
    .game-tag {
        font-size: 10px;
        margin-right: 5px;
        color: #9f9f9f;
        white-space: nowrap;
    }
    .game-menu{
        display: flex;
        flex-direction: column;
        background:rgba(255,255,255,.1);
        margin-top: 8px;
        align-items: flex-start;
        color: #ffffff;
    }
    .no-item-view{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }
    .none-text{
        margin: 20px 0;
        font-size: 14px;
        color: #aaaaaa;
        text-align: center;
    }
    .role-view{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        width: 90%;
        margin: 0 auto;
    }
    .line{
        width: 90%;
        height: 0.5px;
        margin: 0 auto;
        background-color: #EEEEEE;
    }
    .game-detail-info{
        text-align: left;
        color: #efa738;
    }
    .recharge-info {
        font-size: 11px;
        color: #aaaaaa;
        padding: 0 20px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .get-button {
        padding: 2px 5px;
        margin-right: 5px;
        border-radius: 3px;
        color: #ED9015;
        border: 1px solid #ED9015;
    }
    .get-button-text {
        font-size: 10px;
        font-weight: bold
    }
</style>