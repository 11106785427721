import { render, staticRenderFns } from "./Weixin.vue?vue&type=template&id=e40a933a&scoped=true"
import script from "./Weixin.vue?vue&type=script&lang=js"
export * from "./Weixin.vue?vue&type=script&lang=js"
import style0 from "./Weixin.vue?vue&type=style&index=0&id=e40a933a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e40a933a",
  null
  
)

export default component.exports