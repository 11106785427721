<template>
    <div class="main-container">
        <div class="dialog-title">
            <img @click="back"  src="../assets/back.png" style="width: 25px;height: 25px;">
            <div style="margin-left: 5px;">礼包领取记录</div>
        </div>

        <div class="main-view">
            <div v-if="recordList.length === 0" style="background:rgba(255,255,255,.1);height: 80px;display: flex;justify-content: center;align-items: center;">
                <span class="none-text">暂无领取记录</span>
            </div>

            <div style="width: 100%">
                <div v-for="(item,index) in recordList" :key="item.index" style="margin-top: 1px;" class="record-list">
                    <div @click="open(index)" style="display: flex">
                        <img :src="item.gameImgUrl" style="width: 38px;height: 38px;">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between;margin-left: 15px;flex: 1">
                            <div style="display: flex;justify-content: space-between;align-items: center;width: 100%;padding-right: 30px;box-sizing: border-box">
                                <span style="font-size: 12px;font-weight: 700;color: #ef4d4d;">{{item.giftName}}</span>
                                <span v-if="item.status === 2" style="font-size: 12px;color: #dddddd">待审核</span>
                                <span v-if="item.status === 3" style="font-size: 12px;color: #dddddd">驳回</span>
                                <span v-if="item.status === 1" style="font-size: 12px;color: #dddddd">已发放</span>
                                <span v-if="item.status === 4" style="font-size: 12px;color: #dddddd">同组已领</span>
                            </div>
                            <span style="font-size: 11px;color: #dddddd">{{ item.gameName }}</span>
                        </div>
                        <van-icon v-show="!item.open" name="arrow-down" color="#888888" />
                        <van-icon v-show="item.open" name="arrow-up" color="#888888" />
                    </div>
                    <div v-if="item.open" style="text-align: left;padding: 2px 5px;font-size: 12px;margin-top: 10px;color: #cccccc;">
                        <div style="display: flex;justify-content: space-between">
                            <span style="">{{item.serverName}} / {{item.roleName}}</span>
                            <span style="">{{item.getTime}}</span>
                        </div>
                        <div v-if="!!item.giftCode" style="display: flex;justify-content: flex-start; margin-top: 5px;">
                            <span>
                                礼包码 : <span style="color: #ef4d4d">{{item.giftCode}}</span>
                            </span>
                            <div @click="copyCode(item.giftCode)" class="get-button" style="margin-left: 15px;">复制</div>
                        </div>
                        <div v-if="!!item.checkResultMsg" style="display: flex;margin-top: 5px;">
                            驳回原因 : {{item.checkResultMsg}}
                        </div>
                    </div>
                </div>
            </div>
            
            <myloading v-show="showLoading"></myloading>
        </div>
    </div>
</template>

<script>
import Myloading from "@/components/myloading.vue";
import {Toast} from "vant";
import {request} from "@/api/request";

export default {
    name: "GiftRecord",
    components: {Myloading},
    data(){
        return {
            recordList: [],
            showLoading: false,
        }
    },
    mounted() {
        this.gameId = localStorage.getItem("gameId");
        this.queryData();
    },
    methods: {
        getParent() {
            let from = localStorage.getItem("from");
            if (from === 'android_sdk') {
                return  this.$parent;
            }
            else {
                return this.$parent.$parent;
            }
        },
        back() {
            this.getParent().hideGiftRecordPage();
        },
        copyCode(v) {
            const input = document.createElement('input')
            document.body.appendChild(input)
            input.setAttribute('value', v)
            input.select()
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            document.body.removeChild(input)
            Toast('礼包码已经复制到剪切板');
        },
        queryData() {
            this.showLoading = true;
            let options = {
                url:"/app/giftRecordList",
                data: {
                    "gameId": this.gameId
                }
            };
            request(options).then(res => {
                this.showLoading = false;
                if (res.data.success) {
                    let d = res.data.data;
                    d.forEach(function(e, i) {
                        e.open = false;
                    });
                    this.recordList = d;
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
        open(index) {
            this.recordList[index].open = !this.recordList[index].open;
        },
    }
}
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }
    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }
    .none-text{
        font-size: 18px;
        color: #aaaaaa;
        text-align: center;
    }
    .main-view{
        width: 95%;
        height: 255px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 5px 15px;
        margin: 0 auto;
    }
    .get-button {
        margin-right: 5px;
        border-radius: 3px;
        color: #ED9015;
    }
    .record-list{
        background:rgba(255,255,255,.1);
        border-radius: 0;
        padding: 8px 10px;
    }
</style>