<template>
    <div class="main-container">
        <div class="dialog-title">
            <img @click="back" src="../assets/back.png" style="width: 25px;height: 25px;">
            <div style="margin-left: 5px;">自助充值</div>
        </div>

        <div class="recharge-main">
            <div class="input-view">
                <van-field label="充值金额" label-width="70" label-class="inline-label" maxlength="5" id="inputAmount"
                           type="digit" :placeholder="balanceText" v-model="amount"/>
            </div>
            <div style="width: 100%;height: 1px; background-color: #cacacb;transform: scaleY(.3)">
            </div>

            <div style="font-size: 12px;text-align: left;color: #888888;margin-top: 20px;">请选择支付方式</div>
            <div style="box-sizing: border-box;margin-top: 10px; color: #eeeeee;">
                <van-radio-group v-model="payType">
                    <div style="display: flex;flex-direction: column;">
                        <div class="pay-item" @click="payType = '2'">
                            <div style="display: flex;align-items: center">
                                <img src="../assets/pay_zfb.png" style="width:17px;height:17px;">
                                <span style="margin-left: 10px;">支付宝</span>
                            </div>
                            <van-radio name="2" shape="square" icon-size="13px" checked-color="#e73d50"/>
                        </div>
                        <!--<div class="pay-item" @click="payType = '4'">
                            <div style="display: flex;align-items: center">
                                <img src="../assets/pay_zfb.png" style="width:17px;height:17px;">
                                <span style="margin-left: 10px;">支付宝扫码</span>
                            </div>
                            <van-radio name="4" shape="square" icon-size="13px" checked-color="#e73d50"/>
                        </div>-->
                        <div class="pay-item" @click="payType = '3'">
                            <div style="display: flex;align-items: center">
                                <img src="../assets/pay_wx.png" style="width:18px;height:18px;">
                                <span style="margin-left: 10px;">微信</span>
                            </div>
                            <van-radio name="3" shape="square" icon-size="13px" checked-color="#e73d50"/>
                        </div>
                        <!--<div class="pay-item" @click="payType = '5'">
                            <div style="display: flex;align-items: center">
                                <img src="../assets/pay_wx.png" style="width:18px;height:18px;">
                                <span style="margin-left: 10px;">微信扫码</span>
                            </div>
                            <van-radio name="5" shape="square" icon-size="13px" checked-color="#e73d50"/>
                        </div>-->
                    </div>
                </van-radio-group>
            </div>

            <div style="margin-top: 30px; display: flex;align-items: center;justify-content: center">
                <van-button type="info" native-type="submit" :loading="showLoading" size="small" round style="width: 160px;"
                            loading-text="" @click="submit"
                            color="#e73d50">
                    立即支付
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {request} from "@/api/request";
    import Toast from "vant/lib/toast";
    import notice from '@/api/notice'
    import QRCode from 'qrcodejs2'

    export default {
        name: "recharge",
        components: {},
        data() {
            return {
                showLoading: false,
                amount: '',
                payType: '',
                balance: '0',
                balanceText: '当前余额：0',
                price: '0',
                paySuccess: false,
                gameId: '',
                from: '',
            }
        },
        mounted() {
            this.gameId = localStorage.getItem('gameId');
            this.from = localStorage.getItem("from");
            this.addInputBlur();
            this.getBalance();
        },
        methods: {
            getParent() {
                if (this.from === 'android_sdk') {
                    return this.$parent;
                } else {
                    return this.$parent.$parent;
                }
            },
            back() {
                this.getParent().closeRechargePage();
            },
            getBalance() {
                let options = {
                    url: "/h5/getBalance",
                    data: {
                        gameId: this.gameId,
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.balance = parseFloat(res.data.data.balance);
                        this.balanceText = '当前余额：' + this.balance;
                    } else {
                        Toast(res.data.error.message);
                    }
                });
            },
            resetPage() {
                this.amount = '';
                this.payType = '';
            },
            submit() {
                if (!this.amount) {
                    Toast('请输入充值金额');
                    return;
                }
                if (!this.payType) {
                    Toast('请选择支付方式');
                    return;
                }

                this.showLoading = true;
                let options = {
                    url: "/h5/ptbOrder",
                    data: {
                        money: this.amount,
                        paymentType: this.handPayType(this.payType),
                        gameId: this.gameId,
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.amount = '';
                        let payUrl = res.data.data.payString;
                        let orderId = res.data.data.orderId;
                        if (this.from.includes('android')) {
                            window.androidJs.openUrl(payUrl);
                        }
                        else {
                            this.$parent.$parent.openPayUrl(payUrl, res.data.data.paymentType, this.amount, this.amount, orderId);
                        }
                        this.getParent().closeRechargePageForPay();

                    } else {
                        Toast(res.data.error.message);
                    }
                });
            },
            handPayType(payType) {
                let isMobile = this.isMobile();
                if (isMobile) {
                    return payType;
                }
                if (payType === '1' || payType === '8') {
                    return payType
                }
                if (payType === '2') {
                    return '4'
                }
                if (payType === '3') {
                    return '5'
                }
            },
            isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return flag;
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if (!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return;
                }
                let t = document.getElementById('inputAmount');
                t.removeEventListener('blur', function () {
                    window.scrollTo(0, 0)
                });
                t.addEventListener('blur', function () {
                    window.scrollTo(0, 0)
                }, false);
            },
        }
    }
</script>

<style scoped>

    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }

    /deep/ .inline-label {
        color: #aaaaaa !important;
    }

    .van-cell {
        padding: 8px 4px;
    }

    .van-field {
        background-color: #2f2f2f;
    }

    /deep/ .van-field__control {
        color: #ffffff;
    }


    .recharge-main {
        display: flex;
        flex-direction: column;
        color: #333333;
        padding: 0 20px;
    }

    .pay-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background:rgba(255,255,255,.1);
        padding: 8px 8px;
        margin-bottom: 1px;
        cursor: pointer;
        font-size: 12px;
    }
</style>