<template>
    <div class="main-container">
        <div class="dialog-title">
            <img @click="back"  src="../assets/back.png" style="width: 25px;height: 25px;">
            <div style="margin-left: 5px;">联系客服</div>
        </div>
        <div class="kf-item">
            <img src="../assets/qqkf-icon.png" style="width: 30px;height: 30px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start;margin-left: 15px;">
                <span style="font-size: 14px;">QQ: 2788188888</span>
                <span style="color: #bbbbbb;margin-top: 5px">在线时间: 09:00-22:00</span>
            </div>
            <div style="flex: 1;text-align: right;">
                <div @click="openQQkf" class="kf-button">联系</div>
            </div>
        </div>
        <div style="height: 1px;background-color: #444444;margin: 0 20px;"></div>

        <div class="kf-item">
            <img src="../assets/call.png" style="width: 30px;height: 30px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start;margin-left: 15px;">
                <span style="font-size: 14px;">电话: 4006183736</span>
                <span style="color: #bbbbbb;margin-top: 5px">工作时间: 09:00-22:00</span>
            </div>
            <div style="flex: 1;text-align: right;">
                <div  @click="call" class="kf-button">复制</div>
            </div>
        </div>
        <div style="height: 1px;background-color: #444444;margin: 0 20px;"></div>

        <div class="kf-item">
            <img src="../assets/wx1.png" style="width: 30px;height: 30px;">
            <div style="display: flex;flex-direction: column;align-items: flex-start;margin-left: 15px;">
                <span style="font-size: 14px;">微信客服</span>
                <span style="color: #bbbbbb;margin-top: 5px">在线时间: 09:00-22:00</span>
            </div>
            <div style="flex: 1;text-align: right;">
                <van-button  @click="openWeixinKf()" :loading="openLoading" loading-text=" " plain color="#ED9015" style="background: transparent;width: 50px;height: 26px;padding: 0;border-radius: 4px;">咨询</van-button>
            </div>
        </div>
        <div style="height: 1px;background-color: #444444;margin: 0 20px;"></div>
        <div style="text-align: left;padding: 0 25px;display: flex;align-items: center">
            <img src="../assets/gzh.png" style="width: 80px;height: 80px;margin-top: 5px;border-radius: 3px;">
            <span style="color: #dddddd;font-size: 14px;margin-left: 20px;">扫码关注公众号</span>
        </div>
    </div>
</template>

<script>
    import notice from "../api/notice";
    import {Toast} from 'vant'

    export default {
        name: 'kf',
        data() {
            return {
                from: '',
                openLoading: false,
            };
        },
        mounted() {
            this.from = localStorage.getItem("from");
        },
        methods: {
            getParent() {
                if (this.from === 'android_sdk') {
                    return this.$parent;
                }
                else {
                    return this.$parent.$parent;
                }
            },
            back() {
                this.getParent().closeKfPage();
            },
            call() {
                /*if (this.from.includes('sdk')) {
                    notice.call('4000022070')
                }
                else {
                }*/
                this.copy('4006183736');
            },
            openWeixinKf() {
                if (this.openLoading) {
                    return;
                }
                this.openLoading = true;
                setTimeout(() => {
                    this.openLoading = false;
                }, 2000)
                let weixinKfUrl = 'https://work.weixin.qq.com/kfid/kfc298fd74660766c12';
                notice.openUrl(weixinKfUrl)
            },
            copy(v) {
                notice.copy(v)
                setTimeout(() => {
                    Toast('复制成功');
                }, 500)
            },
            openQQkf() {
                let qqUrl = 'mqq://im/chat?chat_type=wpa&uin=2788188888&version=1&src_type=web';
                if (this.from.includes('sdk')) {
                    notice.startIntent(qqUrl)
                }
                else {
                    window.open(qqUrl, '_blank');
                }

            }
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }
    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }
    .kf-item {
        display: flex;
        align-items: center;
        justify-content: left;
        text-align: left;
        padding: 0 20px;
        color: #dddddd;
        font-size: 12px;
        height: 55px;
    }

    .kf-button {
        color: #ED9015;
        width: 50px;
        border: 1px solid #ED9015;
        border-radius: 4px;
        text-align: center;
        height: 26px;
        line-height: 28px;
        font-size: 13px;
        display: inline-block;
    }

</style>
