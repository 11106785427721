<template>
    <div class="all-page">
        <home-nav class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                游戏福利
            </div>
        </div>

        <div class="p-title">
            <div style="height: 70px;padding-left: 30px;display: flex;align-items: center;color: #666666">
                <span>当前位置:</span>
                <span style="margin-left: 10px;">首页</span>
                <span style="margin-left: 10px;">></span>
                <span style="margin-left: 10px;">福利</span>
            </div>
        </div>

        <div class="empty-view">
            暂无内容，敬请期待~
        </div>

        <home-tabbar></home-tabbar>
        <home-footer class="m-hidden-view"></home-footer>
    </div>
</template>

<script>
    import {Toast, Dialog} from 'vant'

    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import gzhCodeView from "@/components/home/GzhCodeView";
    import homeTabbar from "@/components/home/HomeTabbar";
    import {request} from "@/api/request";

    export default {
        name: "Fuli",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
            homeTabbar
        },
        data() {
            return {
            }
        },
        methods: {
            back() {
                this.$router.back();
            },
        }
    }
</script>

<style scoped>
    .all-page {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #f8f7f5;
    }
    .p-title {
        width: 100%;
        max-width: 900px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 8px;
        margin: 10px auto 0;
        z-index: 999;
        position: relative;
    }

    .m-title {
        display: none;
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        position: fixed;
        align-items: flex-end;
        padding: 0 20px;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }
    .empty-view {
        width: 100%;
        max-width: 900px;
        margin: 20px auto;
        background-color: #ffffff;
        height: 400px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888888;
        font-size: 18px;
    }
    @media screen and (max-width: 900px) {
        .m-hidden-view {
            display: none !important;
        }
        .p-title {
            display: none;
        }
        .m-title {
            display: flex;
        }
        .empty-view {
            width: 90%;
            margin-top: 80px;
        }
    }
</style>