<template>
    <div class="main-container">
        <van-form @submit="onSubmit" ref="submitForm">
            <div class="dialog-title">
                <img  @click="back" src="../assets/back.png" style="width: 25px;height: 25px;">
                <div style="margin-left: 5px;">绑定手机</div>
            </div>

            <div style="margin: 10px 20px;height: 250px;overflow-y: auto;">

                <div style="color: #aaaaaa;font-size: 12px;text-align: left">
                    您已绑定手机号码，该页面可进行解绑操作。
                </div>

                <van-field label-width="60px" label="手机号码" label-class="inline-label" readonly placeholder="请输入您的手机号" style="margin-top: 10px;"
                           v-model="tel" clearable/>

                <van-field label-width="60px" label="验证码" label-class="inline-label" style="margin-top: 20px;" v-model="verifyCode" type="number"
                           placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                                v-show="showCountDown"
                                ref="countDown"
                                :time="60000"
                                :auto-start="false"
                                format="重新发送(ss秒)"
                                style="font-size: 12px;color: #888888"
                                @finish="finish"
                        />
                        <span style="color: #e73d50;font-size: 12px;cursor: pointer;" v-show="!showCountDown" @click="sendSmsForUnbind">发送验证码</span>
                    </template>
                </van-field>

                <div class="box-button">
                    <van-button type="info" block native-type="submit" size="small" round style="width: 160px;"
                                color="#e73d50">
                        立即解绑
                    </van-button>
                </div>

            </div>
        </van-form>
    </div>
</template>

<script>

    import {sendSms, unBindPhone, request} from '@/api/request'
    import verifyCodeIcon from "@/assets/verifyCode.png";
    import phoneIcon from "@/assets/phone.png";
    import backIcon from "@/assets/back.png";

    import {Toast} from 'vant'

    export default {
        name: 'changePassword',
        data() {
            return {
                phoneIcon,
                verifyCodeIcon,
                backIcon,
                tel: '',
                verifyCode: '',
                codePattern: /^\d{6}$/,
                showCountDown: false,
                showSendText: true,
                disabledSend: false,
            };
        },
        mounted() {
            this.tel = localStorage.getItem('tel')
        },
        methods: {
            getParent() {
                let from = localStorage.getItem("from");
                if (from === 'android_sdk') {
                    return  this.$parent;
                }
                else {
                    return this.$parent.$parent;
                }
            },
            back() {
                this.getParent().hideUnbindPage();
            },
            onSubmit() {
                let ok = this.checkCode();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                let params = {
                    phone: this.tel,
                    checkCode: this.verifyCode,
                };
                unBindPhone(params).then((res) => {
                    if (res.data.success === true) {
                        Toast.success('手机号码解绑成功');
                        let user = JSON.parse(localStorage.getItem('user'));
                        user.phone = null;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.back();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            sendSmsForUnbind() {
                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();

                this.showLoading = true;
                let options = {
                    url: "/h5/checkAndSendSms?type=3",
                    method: 'get'
                };
                request(options).then((res) => {
                    if (res.data.success) {
                        if (res.data.data === '1') {
                            Toast('验证码发送成功');
                        }
                        else {
                            Toast('当前账号未绑定手机，请重新刷新页面');
                        }
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            checkCode() {
                if (!this.verifyCode) {
                    return "请输入验证码";
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    return "验证码为6位数字";
                }
                return 'ok';
            },
            finish() {
                this.disabledSend = false;
                this.showSendText = true;
                this.showCountDown = false;
                this.$refs.countDown.reset();
            }
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }

    /deep/.inline-label {
        color: #aaaaaa !important;
    }

    .van-cell {
        padding: 8px 14px;
    }

    .van-field {
        background-color: #2f2f2f;
        color: #ffffff;
    }
    /deep/.van-field__control {
        color: #ffffff;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
</style>
