import { render, staticRenderFns } from "./taohuali.vue?vue&type=template&id=24c9319c&scoped=true"
import script from "./taohuali.vue?vue&type=script&lang=js"
export * from "./taohuali.vue?vue&type=script&lang=js"
import style0 from "./taohuali.vue?vue&type=style&index=0&id=24c9319c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c9319c",
  null
  
)

export default component.exports