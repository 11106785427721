console.log('process.env', process.env)
const serverList = [
    {
        BASEURL: 'http://192.168.1.26:8401',
    },
    {
        BASEURL: 'https://gameapi.etuw.cn',
    },
];
let serverArr;

serverArr = serverList[1]
export default serverArr
