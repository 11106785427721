<template>
  <div  @click.stop style="z-index: 99999; position: absolute;top: 0;left: 0;background-color: rgba(0,0,0,0.3); width: 100%;height: 100%;display: flex;align-items: center;justify-content: center">
    <div style="width: 100px;height: 100px;border-radius: 5px; background-color: #444444;display: flex;align-items: center;justify-content: center">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'myloading',
  props: {}
}
</script>
