<template>
  <div class="all-panel">
      <van-button v-if="showResult" type="primary" style="margin-top: 300px">{{ text }}</van-button>
  </div>
</template>

<script>
import '@vant/touch-emulator';
import {Toast} from "vant";
import {jspay} from '@/api/request'


export default {
  name: 'wxjsapi',
  data() {
    return {
      showResult: false,
      text: ''
    }
  },
  mounted() {
    let url = window.location.href;
    let code = url.split("?")[1].split("#")[0].split("&")[0].split("=")[1];
    let orderId = url.split("?")[1].split("#")[0].split("&")[1].split("=")[1];
    this.onBridgeReady(code, orderId);
  },
  methods: {
    onBridgeReady(code, orderId) {
      let params = {
        "code": code,
        "orderId": orderId
      }
      jspay(params).then(res => {
        if (res.data.success) {
          let a = this;
          WeixinJSBridge.invoke('getBrandWCPayRequest', {
                "appId": res.data.data.appId,     //公众号ID，由商户传入
                "timeStamp": res.data.data.time,     //时间戳，自1970年以来的秒数
                "nonceStr": res.data.data.random,      //随机串
                "package": "prepay_id=" + res.data.data.prepayId,
                "signType": "RSA",
                "paySign": res.data.data.sign
              },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  a.showResult = true;
                  a.text = "充值成功"
                } else {
                  a.showResult = true;
                  a.text = "充值失败"
                }
              });
        } else {
          Toast(res.data.error);
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.all-panel {
  width: 100%;
  background-color: #d8e0d8;
  overflow-y: auto;
  height: 100vh;

  .tips-panel {
    margin: 300px 15px 0 15px;
    padding: 8px 8px 0;
    text-align: center;
    background-color: #d4ecdc;
    height: 60px;
    font-size: 60px;
    color: #049945;
    line-height: 25px;
  }

  .header-info {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
  }
}
</style>
