<template>
  <div class="main-container">
    <div class="left-top-title">
      <van-image class="back-icon" :src="backIcon" @click="back" width="2rem"></van-image>
      <h2>{{title}}</h2></div>
    <div class="video-panel" v-if="showVideo">
      <video :src="videoSrc" :controls="true" :autoplay="true" width="100%"></video>
    </div>
    <div class="img-panel" v-if="showImg">
      <van-image :src="imgSrc" width="100%"></van-image>
    </div>

  </div>
</template>

<script>

import backIcon from "@/assets/back.png";

export default {
  name: 'videoPage',
  data() {
    return {
      backIcon,
      title: '123',
      type: '',
      showVideo: false,
      showImg: false,
      videoSrc: '',
      imgSrc: '',
    };
  },
  mounted() {
  },
  methods: {
    back() {
      this.$parent.$parent.closeLuckyPage();
    },
    show(title, src, type) {
      if (type === 1) {
        this.openVideo(title, src);
      }
      else {
        this.openImg(title, src);
      }
    },
    openVideo(title, src){
      this.showVideo = true;
      this.title = title;
      this.videoSrc = src;
    },
    openImg(title, src){
      this.showImg = true;
      this.title = title;
      this.imgSrc = src;
    },
  }
}
</script>

<style scoped lang="scss">
.main-container {
  overflow: hidden;
  margin: .5rem .3rem .8rem;
    .left-top-title{
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      justify-content: left;
      width: 100%;
      padding-left: .2rem;
      color: #666666;
      vertical-align: center;
      height: 3rem;
      line-height: 3rem;
      .back-icon{
        display: inline-block;
      }
      h2{
        display: inline-block;
        font-size: 1.3rem;
        margin: 0;
      }

    }
    .video-panel {

    }

}
</style>
