<template>
    <div class="pay-code-panel">
        <img @click="close" src="../assets/close-gray.png" style="width: 13px;height: 13px;position: absolute;right: 15px;top: 10px;">
        <span>{{payName}}支付订单</span>
        <div style="display: flex;margin-top: 25px;font-size: 12px;">
            <div v-show="payType === '5'" class="qr-code" ref="qrCodeUrl"></div>
            <div v-show="payType === '4'" style="width: 130px;height: 130px;">
                <iframe id="iframe" v-show="true" allowfullscreen ref="gameIframe" :src="payCodeUrl" style="flex: 1;transform: scale(0.619);transform-origin: left top;"
                        frameborder="0" scrolling="no" width="210px" height="210px"></iframe>
            </div>

            <div style="margin-left: 15px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: flex-start;">
                <div v-if="payType === '5'" style="display: flex;align-items: center;">
                    <img src="../assets/pay_wx.png" style="width: 25px;height: 25px;">
                    <span style="margin-left: 8px;">微信支付</span>
                </div>
                <div v-if="payType === '4'" style="display: flex;align-items: center;">
                    <img src="../assets/pay_zfb.png" style="width: 25px;height: 25px;">
                    <span style="margin-left: 8px;">支付宝支付</span>
                </div>

                <span style="color: #888888">原始价格：{{payOriginalPrice}}</span>
                <span>应付价格：{{payPrice}}</span>
            </div>
        </div>
        <div style="margin-top: 18px;font-size: 14px;color: #333333;text-align: left">请用{{payName}}扫码二维码完成支付</div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    import {get} from '@/api/request'
    import {Toast} from "vant";

    export default {
        name: 'pay-code',
        data() {
            return {
                payCode: '',
                payCodeUrl: '',
                payType: '',
                payName: '',
                payPrice: '',
                payOriginalPrice: '',
                orderId: '',
                qrCode: {},
                interval: 0,
            }
        },
        mounted() {

        },
        methods: {
            init(data) {
                this.payType = data.payType;
                this.payPrice = data.payPrice;
                this.payOriginalPrice = data.payOriginalPrice;
                this.orderId = data.orderId;
                if (this.payType === '4') {
                    this.payName = '支付宝';
                    this.payCodeUrl = data.payCode;
                } else if (this.payType === '5') {
                    this.payName = '微信';
                    this.payCode = data.payCode;
                    this.creatQrCode();
                }

                this.interval = setInterval(() => {
                    this.queryPayCodeStatus(this.orderId);
                }, 2000);
            },
            creatQrCode() {
                //console.log(this.$refs.qrCodeUrl.innerHTML = "");
                this.$refs.qrCodeUrl.innerHTML = ""
                if (this.qrCode._oQRCode) {
                    this.qrCode.clear(); //清除
                }
                this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.payCode,
                    width: 130,
                    height: 130,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: 3

                });
            },
            close() {
                clearInterval(this.interval);
                this.$parent.$parent.payCodeShow = false;
            },
            queryPayCodeStatus (orderId) {
                let url = "/h5/queryPayCodeStatus?orderId=" + orderId;
                get(url).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let code = res.data.data;
                        if (code === 1) {//待支付，继续请求

                        }
                        else if (code === 0) {//已过期
                            this.close();
                            Toast('订单已过期，如要支付请重新下单');
                        }
                        else if (code === 2) {//已支付，关闭窗口
                            this.close();
                            Toast('订单支付成功');
                        }
                    }
                    else {
                        this.close();
                        Toast(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .pay-code-panel {
        position: relative;
        color: #333333;
        padding: 25px 15px 25px;
        box-sizing: border-box;
    }

    .qr-code {
        display: inline-block;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .qr-code img {
        /*实现垂直居中*/
        align-items: center;
        justify-content: left;
        margin: 0 auto;
        display: unset !important;
    }
</style>
