<template>
    <div class="back-div">
        <div class="main-page" ref="mainPage">
            <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>

<script>

import {request} from '@/api/request'
import {Toast} from 'vant'

export default {
    name: 'taohuali',//桃花里 联运
    data() {
        return {
            gameId: '',
            userId: '',
            userName: '',
            time: '',
            localUserId: '',
            gameUrl: '',
            sdkUrl: 'https://tao648.cn/static/js/taohuali.sdk.js',
            sdkInstance: {},
        };
    },
    mounted() {
        this.importJs();
        this.gameId = this.$route.query.gameId;
        this.userId = this.$route.query.userId;
        this.userName = this.$route.query.userName;
        this.time = this.$route.query.time;
        
        //localStorage.setItem("gameId", this.gameId);
        this.getGameUrl();
        this.initListener();
    },
    methods: {
        importJs() {
            const oScript = document.createElement('script');
            oScript.type = 'text/javascript';
            oScript.src = this.sdkUrl;
            document.body.appendChild(oScript);
            setTimeout(() => {
                this.initSDK();
            }, 2000);
        },
        getGameUrl() {
            if (!this.gameUrl) {
                let sign = this.$route.query.sign;
                let options = {
                    url: "/dispatch/taohuali/getGameUrl",
                    data: {
                        gameId: this.gameId,
                        userId: this.userId,
                        userName: this.userName,
                        time: this.time,
                        sign: sign,
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        if (res.data.data.screenType === 1) {
                            this.$refs.mainPage.style.maxWidth = "600px";
                        }
                        this.gameUrl = res.data.data.loginUrl;
                        this.localUserId = res.data.data.userId;
                        console.log('返回游戏页面', this.gameUrl);
                    } else {
                        Toast.fail('拉取游戏数据异常');
                    }
                });
            }
        },
        initSDK() {
            console.log('h5 sdk init...');
            this.sdkInstance = window.TAOHUALI_H5_SDK;
            let params = {
                gameId: "0", //游戏的ID
            };
            this.sdkInstance.config(params); //初始化
            console.log('sdkInstance init success');
        },
        initListener() {
            window.addEventListener("message", this.listener);
        },
        listener(event) {
            let me = this;
            if (event.data.operation === 'pay') {
                console.log("sdk触发支付");
                let order = event.data.param;
                me.preOrder(order);
            } else if (event.data.operation === 'uploadGameRole') {
                console.log("sdk触发角色上报");
                let param = event.data.param;
                me.uploadRole(param);
            } else if (event.data.operation === 'repeatLogin') {
                console.log("sdk触发登陆被顶");
                me.logout();
            }
        },
        preOrder(order) {
            order.cpOrder = order.cpOrderId;
            let options = {
                url: "/dispatch/taohuali/preOrder",
                data: order
            };

            request(options).then(res => {
                if (res.data.success) {
                    let sdkOrder = {
                        gameId: res.data.data.gameId,
                        userId: this.userId,
                        serverId: order.serverId,
                        time: res.data.data.time,
                        roleId: order.roleId,
                        roleName: order.roleName,
                        goodsName: order.goodsName,
                        money: order.money,
                        cpOrderId: res.data.data.orderId,
                        sign: res.data.data.sign
                    };
                    console.log('预下单参数', JSON.stringify(sdkOrder));
                    this.sdkInstance.pay(sdkOrder);
                } else {
                    Toast.fail(res.data.error.message);
                }
            });
        },
        uploadRole(roleParam) {
            let options = {
                url: "/dispatch/taohuali/roleSync",
                data: roleParam
            };

            request(options).then(res => {
                if (res.data.success) {
                    console.log("上报后台角色信息成功");
                    roleParam.gameId = res.data.data.gameId;
                    roleParam.userId = this.userId;
                    roleParam.sign = res.data.data.sign;
                    this.sdkInstance.uploadGameRole(roleParam);
                } else {
                    console.log("上报后台角色信息失败");
                }
            });
        },
        logout() {
            this.sdkInstance.repeatLogin();
            console.log("登出");
        },
    }
}
</script>

<style scoped lang="scss">
.back-div {
    background-color: #eceaea;

    .main-page {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        touch-action: none;
        margin: 0 auto;

        .main-dialog {

        }

        .loading-dialog {
            position: fixed;
            top: 40%;
            left: 20%;
            width: 60%;
            height: 5rem;
        }
    }
}


</style>
