<template>
    <div class="main-container">
        <div class="dialog-title">
            <img @click="back"  src="../assets/back.png" style="width: 25px;height: 25px;">
            <div style="margin-left: 5px;">游戏加速</div>
        </div>

        <div style="display: flex;justify-content: space-between;margin-top: 30px;padding: 0 40px;user-select: none">
            <div @click="setSpeed(0.2)" class="speed-button" :class="speedRate === 0.2 && 'speed-button-active'">
                X0.2
            </div>
            <div @click="setSpeed(0.5)" class="speed-button" :class="speedRate === 0.5 && 'speed-button-active'">
                X0.5
            </div>
            <div @click="setSpeed(1)" class="speed-button" :class="speedRate === 1 && 'speed-button-active'">
                X1
            </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;padding: 0 40px;">
            <div @click="setSpeed(2)" class="speed-button" :class="speedRate === 2 && 'speed-button-active'">
                X2
            </div>
            <div @click="setSpeed(5)" class="speed-button" :class="speedRate === 5 && 'speed-button-active'">
                X5
            </div>
            <div @click="setSpeed(10)" class="speed-button" :class="speedRate === 10 && 'speed-button-active'">
                X10
            </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 20px;padding: 0 40px;">
            <div @click="setSpeed(15)" class="speed-button" :class="speedRate === 15 && 'speed-button-active'">
                X15
            </div>
             <div @click="setSpeed(20)" class="speed-button" :class="speedRate === 20 && 'speed-button-active'">
                X20
            </div>
            <div @click="setSpeed(30)" class="speed-button" :class="speedRate === 30 && 'speed-button-active'">
                X30
            </div>
        </div>
    </div>
</template>

<script>
    import {Toast} from 'vant'
    import backIcon from "@/assets/back.png";

    export default {
        name: 'kf',
        data() {
            return {
                backIcon,
                speedRate: 1,
            };
        },
        mounted() {

        },
        methods: {
            getParent() {
                let from = localStorage.getItem("from");
                if (from === 'android_sdk') {
                    return  this.$parent;
                }
                else {
                    return this.$parent.$parent;
                }
            },
            back() {
                this.getParent().closeJsPage();
            },
            setSpeed(n) {
                this.speedRate = n;
                this.getParent().setSpeed(n);
            },
        }
    }
</script>

<style scoped>
    .main-container {
        overflow: hidden;
    }

    .dialog-title {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0 10px;
        width: 100%;
        vertical-align: center;
        font-size: 16px;
        color: #ED9015;
    }

    .speed-button {
        font-weight: bold;
        width: 60px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        color: #ffffff;
        background-color:  #e73d50;
        border: 2px solid #e73d50;
        border-radius: 30px;
        text-align: center;
        cursor: pointer;
    }
    .speed-button-active {
        color: #e73d50;
        background-color: unset;
    }
</style>
