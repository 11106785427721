import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  Button,
  Icon,
  Grid,
  GridItem,
  Image as VanImage,
  Search,
  Divider,
  Tag,
  Calendar,
  List,
  Cell,
  CellGroup,
  Dialog,
  Toast,
  NavBar,
  Picker,
  Popup,
  Field,
  Form,
  CountDown,
  Loading,
  Popover,
  Checkbox,
  Col,
  Row,
  RadioGroup,
  Radio,
  Overlay,
  Tab,
  Tabs,
  Empty,
  Sticky,
  NoticeBar,
} from 'vant';
import 'vant/lib/index.css';

Vue.use(Button)
Vue.use(Icon)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Search)
Vue.use(Divider);
Vue.use(Tag);
Vue.use(Calendar);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Form);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Row);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Sticky);
Vue.use(NoticeBar);

Vue.config.productionTip = false

router.beforeEach(( to, from, next ) => {
  /*if (to.name !== 'login') {
    let token = localStorage.getItem('token');
    console.log('token', token);
    if (!token) {
      window.location.href = '#/login'
      window.location.reload()
    } else {
      localStorage.setItem('beforeUrl', from.fullPath);
      next();
    }
  } else {
    next();
  }*/
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

router.afterEach(() => {
  // NProgress.done()
})

//  全局组件之间通信
Vue.prototype.$viewerBus = Vue.prototype.$viewerBus || new Vue()

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
