<template>
    <div class="main-container">
        <van-button @click="pay">支付</van-button>
        <van-button @click="uploadGameRole">上报角色</van-button>
        <van-button @click="repeatLogin">登录被顶</van-button>
    </div>
</template>

<script>

    import etuwanSdk from '@/utils/etuwan.sdk.js';
    import {md5} from '@/utils/md5'
    import {Dialog, Toast} from 'vant'

    export default {
        name: 'gameTest',
        data() {
            const params = {
                gameId: '1205', //游戏的ID
            }
            return {
                etuwanSdk: window.ETUWAN_H5_SDK,
                params: params,
            };
        },
        mounted() {
            this.etuwanSdk.config(this.params); //初始化
        },
        methods: {
            uploadGameRole() {
                var roleParam = {
                    "gameId":  this.params.gameId,
                    "userId": "1",
                    "serverId": "1",
                    "serverName": "昊天二区",
                    "roleId": "1",
                    "roleName": "⻆⾊名",
                    "remark": "{'serverId':'1','serverName':'昊天二区'}",
                };//参数说明请参考⽂档
                this.etuwanSdk.uploadGameRole(roleParam);
            },
            //⽤户点击商品下单
            pay() {
                let order = {
                    gameId: this.params.gameId,
                    userId: "1",
                    serverId: "10001",
                    time: new Date().getTime() + "",
                    roleId: "111",
                    roleName: "测试人员",
                    goodsName: "600元宝",
                    money: "30",
                    cpOrderId: "testH5Order_" + (Math.floor(Math.random() * 99999999) + 1),
                };

                let sortedParam = this.sorter(order);
                let needSignatureStr = "";
                for (let key in sortedParam) {
                    let value = sortedParam[key];
                    needSignatureStr = needSignatureStr + key + '=' + value + '&';
                }
                needSignatureStr += '05880e72acb54c83a69fc9458706b4d1';
                console.log('需加密串:' + needSignatureStr);
                let sign = md5(needSignatureStr)
                order.sign = sign;
                console.log('加密结果:' + sign);
                this.etuwanSdk.pay(order);
            },
            sorter(inObject) {
                let sortedJson = {};
                let sortedKeys = Object.keys(inObject).sort();
                for (let i = 0; i < sortedKeys.length; i++) {
                    sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
                }
                return sortedJson;
            },
            //登录被顶
            repeatLogin() {
                this.etuwanSdk.repeatLogin();
            },

            success() {
                console.log('上报成功啦')//该⽅法仅供参考
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-container {

    }
</style>
