<template>
    <div class="main-view" ref="mainView">
        <home-nav class="m-hidden-view"></home-nav>

        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title" ref="mainTitle">
            <div style="display: flex;height: 70px;align-items: center;justify-content: space-between;width: 100%;">
                <span>首页</span>
                <img @click="goAppSearch" v-show="showSearchIcon" src="../../assets/search.png" style="width: 25px;height: 25px;margin-right: 15px;" >
            </div>
        </div>
        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 900px;margin: 0 auto;justify-content: space-between;">
            <div class="p-title" style="width: 100%;background-color: #ffffff;border-radius: 10px;margin-top: 20px;height: fit-content">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                </div>
            </div>

            <div @click="goAppSearch" class="search-input-button p-hidden-view">
                <img src="../../assets/search.png" style="width: 22px;height: 22px;" >
                <span style="margin-left: 8px;">{{webConfig.defaultSearchName}}</span>
            </div>

            <div class="top-game-view">
                <div class="game-video-view" v-for="item in topGameList">
                    <video v-if="!!item.videoUrl" :src="item.videoUrl" style="width: 100%; object-fit:cover;border-radius: 5px;margin-bottom: 20px;"
                           :poster="item.videoImgUrl" :controls="true" :autoplay="false"></video>
                    <div class="game-item-view"  @click="open(item.game_id)">
                        <img :src="item.image_url" style="width: 70px;height: 70px;border-radius: 10px;">
                        <div style="display: flex;height: 70px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                            <span class="game-name-one-line">{{item.game_name}}</span>
                            <div class="game-tag-view">
                                <span v-for="tag in item.tagList">{{tag}}</span>
                            </div>
                            <div style="font-size: 13px;color: #FFB300;text-align: left">
                                {{handlerTopic(item.game_topic)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="game-list-view">
                <span class="game-column-name">热推游戏</span>
                <div class="game-item-view game-list-item" v-for="item in hotGameList" @click="open(item.game_id)">
                    <img :src="item.image_url" class="game-item-img">
                    <div class="game-item-info">
                        <div style="text-align: left">
                            <span class="game-name-one-line">{{item.game_name}}</span>
                            <span class="m-hidden-view" style="font-size: 13px;color: #FFB300;text-align: left;margin-left: 15px;">
                                {{handlerTopic(item.game_topic)}}
                            </span>
                        </div>
                        <div class="game-tag-view">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                        <div class="m-hidden-view" style="font-size: 13px;color: #666666;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;">
                            {{item.game_desc}}
                        </div>
                        <span class="p-hidden-view" style="font-size: 13px;color: #FFB300;text-align: left;">
                                {{handlerTopic(item.game_topic)}}
                        </span>
                    </div>
                </div>

            </div>

        </div>

        <home-footer class="m-hidden-view"></home-footer>

        <home-tabbar></home-tabbar>

    </div>


</template>

<script>
    import {request} from '@/api/request'
    import {Toast} from 'vant'

    import gzhCodeView from "@/components/home/GzhCodeView";
    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import homeTabbar from "@/components/home/HomeTabbar";

    import { Tabbar, TabbarItem } from 'vant';
    import Vue from 'vue';
    import config from "@/api/config";
    import notice from "@/api/notice";
    Vue.use(Tabbar);
    Vue.use(TabbarItem);

    export default {
        name: 'gameHome',
        components: {
            homeNav,
            gzhCodeView,
            homeFooter,
            homeTabbar,
        },
        data() {
            return {
                active: 0,
                gameSearchInput: '',
                emptyGameList: false,
                gameList: [],
                topGameList: [],
                hotGameList: [],
                webConfig: {},
                showSearchIcon: false,
            }
        },
        mounted() {
            this.initPage();
            this.queryIndexGame();
            if (this.isSafari()) {
                this.safariStyle = 'padding-bottom: 113px';
            }
        },
        methods: {
            initPage() {
                if (this.$route.query.channelId) {
                    localStorage.setItem('channelId', this.$route.query.channelId);
                }

                if (this.$route.query.from) {
                    localStorage.setItem('from', this.$route.query.from);
                }

                if (this.$route.query.device) {
                    localStorage.setItem('device', this.$route.query.device);
                }

                if (!localStorage.getItem('udid') && this.$route.query.udid) {
                    localStorage.setItem('udid', this.$route.query.udid);
                }
                if (!localStorage.getItem('udid')) {
                    localStorage.setItem('udid', this.generateUUID());
                }

                if (this.$route.query.version) {
                    localStorage.setItem('version', this.$route.query.version);
                }

                this.webConfig = config;
                this.$refs.mainView.addEventListener('scroll', this.onPageScroll);
            },
            onPageScroll() {
                let scrollTop = this.$refs.mainView.scrollTop;
                if (scrollTop === 0) {
                    this.$refs.mainView.style.background = 'url(' + require('@/assets/m-main-background.png') +')'
                    this.$refs.mainView.style.backgroundSize = '100% 100%'
                    this.$refs.mainTitle.style.backgroundColor = 'unset';
                    this.showSearchIcon = false;
                }
                else {
                    this.$refs.mainTitle.style.backgroundColor = '#f8f7f5';
                    this.$refs.mainView.style.background = "unset";
                    this.$refs.mainView.style.backgroundColor = "#f8f7f5";
                    this.showSearchIcon = true;
                }
            },
            queryIndexGame() {
                this.showLoading = true;
                let options = {
                    url:"/web/queryIndexGame",
                    method: 'get'
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        let left = data.leftInfo;
                        let right = data.rightInfo;
                        this.topGameList.push(left);
                        this.topGameList.push(right);
                        this.hotGameList = data.hotList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            handlerTopic(t) {
                switch (t) {
                    case '1':
                        return '卡牌';
                    case '2':
                        return '传奇';
                    case '3':
                        return '武侠';
                    case '4':
                        return '角色';
                    case '5':
                        return '养成';
                    case '6':
                        return '三国';
                    case '7':
                        return '模拟';
                    case '8':
                        return '策略';
                    case '9':
                        return '休闲';
                }
            },
            open(gameId) {
                //window.open(url, '');
                let channelId = localStorage.getItem('channelId') || 1;
                this.$router.push('/app/' + channelId + '/' + gameId);
            },
            isSafari() {
                return (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent));
            },
            goAppSearch() {
                this.$router.push('/home/appSearch');
            },
            generateUUID(){
                let d=new Date().getTime();
                let uuid ='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                    let r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
        }
    }
</script>
<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #f8f7f5;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }

    .top-game-view {
        display: flex;
        justify-content: space-between;
    }

    .game-video-view {
        width: 430px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 20px 25px 5px;
        box-sizing: border-box;
    }

    .game-item-view {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        box-sizing: border-box;
        transition: all 0.15s ease-out;
    }
    .game-list-item {
        margin-top: 20px;
    }
    .game-item-view:hover .game-name-one-line {
        color: #0e7dde;
    }
    .game-video-view:hover,.game-list-item:hover  {
        transform: translateY(-3px);
    }
    .game-name-one-line {
        width: 300px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #333333
    }
    .game-tag-view {
        display: flex;
        color: #f15858;
        font-size: 12px;
    }
    .game-tag-view span {
        margin-right: 10px;
        background-color: rgba(241, 88, 88, 0.1);
        padding: 2px 4px;
        border-radius: 2px;
        white-space: nowrap;
    }

    .m-title {
        display: none;
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 20px;
        position: fixed;
        z-index: 999;
        justify-content: space-between;
        box-sizing: border-box;
    }
    .game-list-view {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 25px;
        box-sizing: border-box;
    }

    .game-column-name {
        font-size: 30px;
        font-weight: bold;
        color: #1277f3;
        text-align: left;
        margin-bottom: 10px
    }
    .game-item-img {
        width: 100px;
        height: 100px;
        border-radius: 10px;
    }
    .game-item-info {
        display: flex;
        height: 90px;
        margin-left: 15px;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }
    .search-input-button {
        height: 45px;
        background-color: #ffffff;
        border-radius: 35px;
        margin: 110px 25px 0;
        display: flex;
        align-items: center;
        padding: 0 15px;
        color: #aaaaaa;
        font-size: 16px;
    }

    @media screen and (max-width: 900px) {
        .main-view {
            background: url("../../assets/m-main-background.png");
            background-size: 100% 100%;
        }
        .p-title {
            display: none;
        }

        .m-hidden-view {
            display: none !important;
        }
        .m-title {
            display: flex;
        }
        .top-game-view {
            flex-direction: column;
            margin-top: 10px;
        }
        .game-video-view {
            width: 90%;
            margin: 10px auto 0;
            padding: 10px 15px 5px;
        }
        .game-list-view {
            width: 90%;
            margin: 10px auto 60px;
            padding: 15px 15px;
        }
        .game-item-img {
            width: 80px;
            height: 80px;
        }
        .game-item-info {
            height: 75px;
        }
        .game-column-name {
            font-size: 22px;
        }
        .game-list-item {
            margin-top: 10px;
        }
    }

    @media screen and (min-width: 900px) {
        .p-hidden-view {
            display: none;
        }
    }
</style>

