<template>
    <div class="main-view">
        <div>
            <dialog-main-menu ref="mainDialog" v-if="mainShow"></dialog-main-menu>
            <kf v-if="kfShow"></kf>
            <gamespeed ref="gameSpeedDialog" v-if="jsPageShow"></gamespeed>
            <real-name ref="realNameDialog" v-if="realNameShow"></real-name>
            <unbind-tel v-if="unBingTelShow"></unbind-tel>
            <bind-tel ref="bindTelDialog" v-if="bingTelShow"></bind-tel>
            <change-password v-if="changePasswordShow"></change-password>
            <recharge v-if="rechargeShow"></recharge>
        </div>
    </div>
</template>

<script>
    import {logout} from '@/api/request'
    import notice from '@/api/notice'

    import changePassword from "@/components/changepassword.vue";
    import bindTel from "@/components/bindtel.vue";
    import unbindTel from "@/components/unbindtel.vue";
    import realName from "@/components/realname.vue";
    import pay from "@/components/pay.vue";
    import DialogMainMenu from "@/components/DialogMainMenu.vue";
    import kf from "@/components/kf.vue";
    import gamespeed from "@/components/gamespeed.vue";
    import gift from "@/components/Gift.vue";
    import recharge from "@/components/Recharge.vue";

    import {Dialog, Toast} from 'vant';


    export default {
        name: "SdkMain",
        components: {
            recharge,
            'change-password': changePassword,
            'bind-tel': bindTel,
            'unbind-tel': unbindTel,
            'real-name': realName,
            'pay': pay,
            'dialog-main-menu': DialogMainMenu,
            'kf': kf,
            'gamespeed': gamespeed,
            'gift': gift
        },
        data() {
            return {
                mainShow: false,
                changePasswordShow: false,
                bingTelShow: false,
                unBingTelShow: false,
                realNameShow: false,
                kfShow: false,
                openPageShow: false,
                jsPageShow: false,
                rechargeShow: false,
            }
        },
        mounted() {
            this.handleUrl();
            this.initPage();
            this.mainShow = true;
        },
        methods: {
            initPage() {

            },
            handleUrl() {
                this.from = this.$route.query.from;
                let token = this.$route.query.token || '';
                this.gameId = this.$route.query.gameId || '';

                if (!token) {
                    Toast('当前登录状态已失效，请重新登录游戏');
                    return ;
                }
                if (!this.gameId) {
                    Toast('游戏参数非法');
                    return ;
                }

                let speed = localStorage.getItem('game_speed') || '1';
                localStorage.clear();
                localStorage.setItem('game_speed', speed);
                localStorage.setItem('from', this.from);
                localStorage.setItem('token', token);
                localStorage.setItem('gameId', this.gameId);
            },
            openFuliPage() {
                let drawUrl = 'https://game.etuw.cn/w';
                notice.openFullDialog(drawUrl);
            },
            openGiftPage() {
                this.$router.push("/gift");
            },
            openWheelPage() {
                let token = localStorage.getItem('token');
                let openId = 'oTi-P6mUF6bZbDxFcETXrcI5vAFw';
                let drawUrl = `https://recharge.etuw.cn/#/award?token=${token}&gameId=${this.gameId}&openId=${openId}`;
                notice.openFullDialog(drawUrl);
            },
            openRedPackPage() {
                let token = localStorage.getItem('token');
                let openId = 'oTi-P6mUF6bZbDxFcETXrcI5vAFw';
                let drawUrl = `https://recharge.etuw.cn/#/redPackageYu?token=${token}&gameId=${this.gameId}&openId=${openId}`;
                notice.openFullDialog(drawUrl);
            },
            hideMainPage() {
                this.mainShow = false;
            },
            showMainPage() {
                this.mainShow = true;
            },
            showKfPage() {
                this.hideMainPage();
                this.kfShow = true;
            },
            closeKfPage() {
                this.kfShow = false;
                this.showMainPage();
            },
            closeJsPage() {
                this.jsPageShow = false;
                this.showMainPage();
            },
            showChangePage() {
                this.hideMainPage();
                this.changePasswordShow = true;
            },
            hideChangePage() {
                this.changePasswordShow = false;
                this.showMainPage();
            },
            showRealNamePageReadOnly() {
                this.hideMainPage();
                this.realNameShow = true;
                this.$nextTick(function () {
                    this.$refs.realNameDialog.inputEnable = false;
                    this.$refs.realNameDialog.initData();
                })
            },
            hideRealNamePage() {
                this.realNameShow = false;
                this.showMainPage();
            },
            showBindPage() {
                this.hideMainPage();
                this.bingTelShow = true;
            },
            hideBindPage() {
                this.bingTelShow = false;
                this.showMainPage();
            },
            showUnbindPage() {
                this.hideMainPage();
                this.unBingTelShow = true;
            },
            hideUnbindPage() {
                this.unBingTelShow = false;
                this.showMainPage();
            },
            openRechargePage() {
                this.hideMainPage();
                this.rechargeShow = true;
            },
            closeRechargePage() {
                this.rechargeShow = false;
                this.showMainPage();
            },
            closeMainWindow() {
                notice.close();
            },
            logout() {
                logout().then(res => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('loginUrl');
                    notice.logout();
                })
            },
        }

    }
</script>

<style scoped>
    .main-view {
        background-color: #2f2f2f;
        border-radius: 12px;
        width: 100vw;
        height: 100vh;
    }
</style>