<template>
    <div class="main-view">
        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                关于
            </div>
        </div>

        <div class="about-main">
            <div style="margin-top: 80px;display: flex;flex-direction: column;align-items: center;">
                <img :src="webConfig.logoUrl" style="width: 90px;height: 90px;">
                <span style="font-size: 22px;margin-top: 10px;color: #333333;">{{webConfig.gameAppName}}</span>
                <span style="margin-top: 10px;font-size: 18px;color: #666666;font-weight: normal;">版本号 {{version}}</span>
            </div>

            <div style="display: flex;flex-direction: column;align-items: center;font-size: 14px;color: #aaaaaa;margin-bottom: 30px;">
                <div style="margin-bottom: 5px;">
                    <span @click="openUser" style="color: #5872b0">《用户协议》</span>
                    <span @click="openPrivacy" style="color: #5872b0">《隐私政策》</span>
                </div>
                <div style="margin-bottom: 5px;">Copyright © 2024 </div>
                <div>{{webConfig.copyrightCompany}}</div>
            </div>
        </div>


    </div>
</template>
<script>

    import webConfig from "@/api/config";
    import notice from "@/api/notice";

    export default {
        name: 'app-about',
        components: {
        },
        data() {
            return {
                webConfig: {},
                version: '',
            }
        },
        mounted() {
            this.version = localStorage.getItem('version');
            this.webConfig = webConfig;
        },
        methods: {
            back() {
                this.$router.back();
            },
            openUser() {
                notice.startIntent(this.webConfig.agreement);
            },
            openPrivacy() {
                notice.startIntent(this.webConfig.privacy);
            },
        }
    }
</script>

<style scoped>
    .about-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        padding-top: 60px;
        box-sizing: border-box;
    }
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #ffffff;
    }
    .m-title {
        display: flex;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #ffffff;
        box-sizing: border-box;
    }
</style>
