/**
 * E兔玩H5游戏SDK
 * @version v1.0
 * @date 2023-12-07
 */

let _h5_SDK = window.ETUWAN_H5_SDK || {};
_h5_SDK.clientVersion = "1.0.0";
_h5_SDK.gameId = 0;
_h5_SDK.init = function () {

};

//sdk配置信息
_h5_SDK.config = function (params) {

    _h5_SDK.gameId = params.gameId;//游戏id

};

//用户进入支付页面
_h5_SDK.pay = function (order) {
    sendMsgToParent({operation: "pay", param: order});
    try {
        window.androidWebJS.pay(JSON.stringify(order));
    } catch (e) {
    }
};

//上报角色
_h5_SDK.uploadGameRole = function (param) {
    sendMsgToParent({operation: "uploadGameRole", param: param});
    try {
        window.androidWebJS.uploadGameRole(JSON.stringify(param));
    } catch (e) {
    }
};

//登陆被顶
_h5_SDK.repeatLogin = function () {
    sendMsgToParent({operation: "repeatLogin"});
    try {
        window.androidWebJS.repeatLogin();
    } catch (e) {
    }
};

_h5_SDK.init();
window.ETUWAN_H5_SDK = _h5_SDK;

function sendMsgToParent(post) {
    window.parent.postMessage(post, '*')
}
